import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Controls from './Controls'; // This component will render the charts.
import ChartComponent from './ChartComponent'; // This component will render the region and location selectors.
import apiBaseUrl from './config';

function App() {
	const [regions, setRegions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [data, setData] = useState(null);

	useEffect(() => {
    fetch(`${apiBaseUrl}/regions`)
      .then(response => response.json())
      .then(data => setRegions(data));
  }, []);

	useEffect(() => {
    if (selectedRegion) {
      fetch(`${apiBaseUrl}/locations?region=${selectedRegion}`)
        .then(response => response.json())
        .then(data => setLocations(data));
    }
  }, [selectedRegion]);

	const handleUpdateCharts = () => {
    fetch(`${apiBaseUrl}/data?region=${selectedRegion}&location=${selectedLocation}`)
      .then(response => response.json())
      .then(data => setData(data));
  };

	return (
    <div className="App">
      <div className="container">
        <Controls
          regions={regions}
          locations={locations}
          selectedRegion={selectedRegion}
          selectedLocation={selectedLocation}
          onRegionChange={setSelectedRegion}
          onLocationChange={setSelectedLocation}
          onUpdateCharts={handleUpdateCharts}
        />
        {data && <ChartComponent data={data} />}
      </div>
    </div>
  );

}

export default App;
