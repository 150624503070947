import React from 'react';

const Controls = ({ regions, locations, selectedRegion, selectedLocation, onRegionChange, onLocationChange, onUpdateCharts }) => {
  return (
    <div className="controls">
      <label htmlFor="region">Select Region:</label>
      <select id="region" value={selectedRegion} onChange={e => onRegionChange(e.target.value)}>
        <option value="">Select a region</option>
        {regions.map(region => (
          <option key={region} value={region}>{region}</option>
        ))}
      </select>

      <label htmlFor="location">Select Location:</label>
      <select id="location" value={selectedLocation} onChange={e => onLocationChange(e.target.value)}>
        <option value="">Select a location</option>
        {locations.map(location => (
          <option key={location} value={location}>{location}</option>
        ))}
      </select>

      <button onClick={onUpdateCharts}>Update Charts</button>
    </div>
  );
};

export default Controls;

