import React from 'react';
import Chart from 'chart.js/auto';
import { Bar, Line } from 'react-chartjs-2';

const ChartComponent = ({ data }) => {

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const barOptionsApartmentPrices = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
		plugins: {
      title: {
          display: true,
          text: 'Apartment Prices in ' + data.currency
      },
			/* datalabels: {
          //color: '#444',
          display: true,
          anchor: 'end',
          align: 'top',
          formatter: (value) => value,
      },
			*/
		}
  };

  const lineOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

	//
	// Making datasets for historical prices chart
  //

  const lineOptionsHistoricalPrices = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
		plugins: {
      title: {
          display: true,
          text: 'Historical Prices by Rooms Count'
      }
		}
  };

	var roomsData = data.historical_prices_by_rooms_count;
  var labels = Object.keys(roomsData[Object.keys(roomsData)[0]]);
	var datasets = [];

	Object.keys(roomsData).forEach((key, index) => {
		datasets.push({
			label: key,
			data: Object.values(roomsData[key]),
			fill: false
		});
  });



  return (
    <div>
		  { data.date }
      <Bar
        data={{
          labels: Object.keys(data.avg_apartment_prices_by_rooms_count),
          datasets: [
						{
            label: 'Average',
            data: Object.values(data.avg_apartment_prices_by_rooms_count),
            backgroundColor: 'rgba(173, 216, 230, 0.2)',
            borderColor: 'rgba(173, 216, 230, 1)',
            borderWidth: 1
            },
						{
            label: 'Median',
            data: Object.values(data.median_apartment_prices_by_rooms_count),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
            },
						{
            label: 'Min',
            data: Object.values(data.min_apartment_prices_by_rooms_count),
            backgroundColor: 'rgba(75, 192, 75, 0.2)',
            borderColor: 'rgba(75, 192, 75, 1)',
            borderWidth: 1
            },
						/*{
            label: 'Max',
            data: Object.values(data.max_apartment_prices_by_rooms_count),
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
            },
						*/
					]
        }}
        options={barOptionsApartmentPrices}
      />
      <Bar
        data={{
          labels: Object.keys(data.sq_meter_prices),
          datasets: [{
            label: 'Prices per Sq Meter',
            data: Object.values(data.sq_meter_prices),
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }]
        }}
        options={barOptions}
      />
      <Bar
        data={{
          labels: Object.keys(data.sea_distance),
          datasets: [{
            label: 'Sea Distance in meters',
            data: Object.values(data.sea_distance),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        }}
        options={barOptions}
      />
      <Line
        data={{
          labels: labels,
					datasets: datasets,
        }}
        options={lineOptionsHistoricalPrices}
      />
      <Line
        data={{
          labels: Object.keys(data.historical_prices_sq_meter),
          datasets: [{
            label: 'Historical Prices per Sq Meter',
            data: Object.values(data.historical_prices_sq_meter),
            backgroundColor: 'rgba(255, 159, 64, 0.2)',
            borderColor: 'rgba(255, 159, 64, 1)',
            borderWidth: 1
          }]
        }}
        options={lineOptions}
      />
    </div>
  );
};

export default ChartComponent;

